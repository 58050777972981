/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-05 12:48:02
 * @modify date 2018-12-05 12:48:02
 * @desc [description]
*/

@import '../../../main';

form#quoteForm {

    border: $border-lightgray;
    min-width: 750px;
    min-height: 500px;

    @media screen and (max-width: 850px) {
        width: 100%;
        min-width: 0;
    }

    > .tabTitles {

        border-bottom: $border-lightgray;
        overflow: auto;

        > ul {

            @include flex;

            margin: 0;
            padding: 0;
        
            > li {

                @include flex;
                @include align-items(center);

                svg {
                    height: 15px;
                    margin-left: 3px;
                }

                margin: 0;
                padding: 10px 35px;
                border-right: 1px solid $border-color-lightgray;
                list-style-type: none;
                cursor: pointer;

                &:last-child {
                    border-right: none;
                }

                &.active {
                    border-bottom: 2px solid $theme-blue;
                    background-color: rgba(0,0,0,0.05);
                }

            }

        }

    }

    > .tabContents {

        padding: 20px;

        > .tabContent {

            display: none;

            &.active {

                display: block;
    
            }

            > .formCategory {

                > .inputs {

                    @include flex;
                    @include flex-direction(column);
                    @include flex-wrap(wrap);

                    margin: 20px 0;

                    &.row {
                        @include flex-direction(row);
                    }

                }

                .checkboxWrapper > input {
                    min-width: 0 !important;
                }

            }

        }

    }

}