/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-14 12:39:53
 * @modify date 2019-05-14 12:39:53
 * @desc [description]
*/

@import '../../main';

.courses {

    @include flex;
    @include flex-wrap(wrap);
    @include justify-content(center);

    margin: 50px 0;

    .card {

        @include box-shadow(0 4px 7px 0 rgba(0,0,0,0.15));
        padding: 15px;
        color: white;
        height: 400px;
        min-width: 250px;

        @media screen and (max-width: 1050px){
            min-width: 200px;
        }

        @media screen and (max-width: 850px){
            min-width: 190px;
        }

        &.active {
            background-color: #4CD137;
            color: white;

            h4 {
                color: white;
            }
        }
        &.dormant {
            background-color: #FCFCFC;
            color: $theme-orange;
        }
        &.dead {
            background-color: #808080;
            color: white;

            h4 {
                color: white;
            }
        }

    }

    .course {

        @include flex-basis(25%);
        @include transition(all 0.125s ease-out);

        position: relative;
        max-width: 420px;
        margin-right: 30px;
        cursor: pointer;

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:hover {
            @include scale(1.05);
        }

        @media screen and (min-width: 800px) {
            min-width: 350px;
        }

        @media screen and (max-width:800px) {
            @include flex-basis(100%);
            margin-right: 0;
            margin-bottom: 30px;
            max-width: auto;
        }

        .date {
            font-size: 25px;
            font-weight: bold;
        }

        h4 {
            margin-top: 0;
            color: $text-default-color;
            height: 50px;
        }

        p {
            width: 100%;
            text-align: left;
            height: 160px;
            overflow: auto;
            padding: 15px 5px 15px 0;
            font-size: 14px;

            &::-webkit-scrollbar {
                width: 8px;
            }
            
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
                border-radius: 3px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background-color: rgba(255,255,255,0.7);
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
            }
        }

        span {
            position: absolute;
            bottom: 25px;
            font-weight: bold;
        }

    }

}