@import '../../../main';

#openPositionsTabContainer {

    @include flex;
    @include justify-content(center);

    border: $border-lightgray;
    width: 50%;

    &::-webkit-scrollbar {
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 3px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(255,255,255,0.7);
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }

    @media screen and (max-width: 1600px){
        width: 60%;
    }

    @media screen and (max-width: 1400px){
        width:70%;
    }

    @media screen and (max-width: 1200px){
        width: 80%;
    }

    @media screen and (max-width: 1000px){
        width: 90%;
    }

    @media screen and (max-width: 750px) {
        width: 75%;
        border-right: none;
    }

    @media screen and (max-width: 600px) {
        width: 90%;
    }

    > .openPositionsList {

        @include flex-grow(1);

        &::-webkit-scrollbar {
            width: 5px;
        }
        
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
            border-radius: 0px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: rgba(0,0,0,0.3);
            // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        }

        min-width: 250px;
        max-width: 250px;
        border-right: $border-lightgray;
        max-height: 576px;
        overflow: auto;

        @media screen and (max-width: 750px) {
            max-width: 750px;
        }

        > h4 {
            background-color: #EBEBEB;
            margin: 0;
            padding: 10px;
            font-weight: 100;
        }

        > .tabTitles {

            > ul {
                margin:0;
                padding:0;

                > li {

                    @include flex;
                    @include justify-content(space-between);

                    padding: 15px;
                    list-style-type: none;

                    &.active {
                        background-color: $theme-blue !important;
                        color: white;
                        border-right: 1px solid $theme-blue;
                        border-left: 1px solid $theme-blue;
                        position: relative;

                        @media screen and (min-width: 750px) {
                            &:after {
                                content: "";
                                border-width: 10px;
                                border-color: transparent;
                                border-style: solid;
                                border-right-color: white;
                                position: absolute;
                                right: -1px;
                                top: calc(50% - 10px);
                            }
    
                        }
                    }

                    &:nth-child(even) {
                        background-color: #F6F2F2;
                    }

                }

            }

        }

    }

    > .tabContentsContainer {

        @include flex-grow(1);

        @media screen and (max-width: 750px) {

            @include transition(transform 0.30s ease-in);
            @include box-shadow(0 15px 60px 0 rgba(0,0,0,0.6));
            @include translate(-750px, 0);
    
            position: fixed;
            top:0;
            left:0;
            width:95%;
            height: 100vh;
            background-color: white;
            z-index: 1;
            overflow: auto;

            &.active {
                @include translate(0,0);
            }
        }

        > button {

            @include align-items(center);
            @include box-shadow(none);

            display: none;
            margin: 15px;

            @media screen and (max-width: 750px) {
                @include flex;
            }

            svg {
                height: 20px;
            }

            > span:not(.isvg) {
                margin-left: 20px;
            }

        }

        > .tabContent {

            padding: 20px;
            display: none;
            max-height: 500px;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 5px;
            }
            
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
                border-radius: 0px;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0px;
                background-color: rgba(0,0,0,0.3);
                // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
            }

            &.active {
                display: block;
            }

            > h2 {

                font-weight: 100;
                margin-top:0;

                > span {
                    display: inline-block;
                    margin-left: 20px;
                }

            }

            > span.label {

                width: 65px;
                display: inline-block;
                text-align: center;

                margin-right: 10px;
                margin-bottom: 5px;

            }

            > .jobDetails {

                @include flex;
                @include justify-content(space-between);
                @include flex-wrap(wrap);

                > div {

                    @include flex-grow(1);
                    margin: 10px;
                    
                    > ol {
                        margin: 0;
                        padding: 0;

                        > li {
                            margin:0;
                            padding: 10px;

                            &:nth-child(odd) {
                                background-color: rgba(196, 196, 196, 0.1);
                            }

                        }

                    }

                }

            }

        }

        > .buttonsContainer {

            padding: 20px;
            border-top: $border-lightgray;

            > button {

                @media screen and (max-width: 380px) {
                    padding: 10px 15px !important;
                }

                &:nth-child(2) {
                    margin-left: 10px;
                }

            }

        }

    }

}

.share {

    > div {

        margin: 5px 0;

        > input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            min-width: 150px;
        }
    
        > button {
    
            @include box-shadow(none);
    
            border-radius: 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 12px;
            border: $border-lightgray;
            background-color: $border-color-lightgray;
            color: inherit;
            min-width: 100px;
        }

    }

}