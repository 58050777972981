/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 13:27:27
 * @modify date 2018-11-16 13:27:27
 * @desc [description]
*/

@import "../../../main";

.navContainer {

    @include flex;
    @include justify-content(space-around);
    @include align-items(center);

    position: relative;

    @media screen and (max-width: $navigation-breakpoint) {

        @include justify-content(space-between);

    }

    > nav {

        @media screen and (max-width: $navigation-breakpoint) {
    
            @include transition(max-width 0.25s ease-out);
    
            max-width: 0;
            position: absolute;
            top: 105px;
            z-index: 3;
            background-color: white;
            width: 60%;
            height: calc(100vh - 154px);
            border-style: solid;
            border-color: $border-color-lightgray;
            border-width: 0;
            overflow: hidden;
    
            &.visible {
                max-width: 60%;
                border-width: 1px;
                border-left-width: 0;
            }
    
            li {
    
                > a {

                    &:before {
    
                        @include scale(1, !important);
        
                        visibility: visible !important;
                        height: 1px !important;
                        background-color: $border-color-lightgray !important;
        
                    }
        
                    &.active {
        
                       color: $theme-blue;
        
                    }
        
                    &:last-child {
        
                        &:before {
        
                            @include scale(1, !important);
            
                            visibility: visible !important;
                            height: 0px !important;
                            background-color: $border-color-lightgray !important;
            
                        }                
        
                    }

                }
    
            }
    
        }
    
        > ul {
    
            @include flex;
    
            padding: 0;
            margin: 0;
    
            @media screen and (max-width: $navigation-breakpoint) {
    
                @include flex-direction(column);
    
            }
    
            > li {
                list-style-type: none;
                position: relative;
    
                > a {

                    color: $text-default-color;
                    text-decoration: none;
                    padding: 10px 30px;
                    display: inline-block;

                    &.active {
    
                        &:before {
                            @include scale(1);
                            visibility: visible;
                        }
         
                    } 
         
                    &:hover {
                        &:before {
        
                            @include scale(1);
        
                            visibility: visible;
                        }
                    }
        
                    &:before {
        
                        @include scale(0);
                        @include transition(all 0.3s ease-in-out);
        
                        content: "";
                        display: block;
                        height: 2px;
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        left: 0;
                        background-color: $theme-blue;
                        visibility: hidden;
        
                    }

                }
    
            }
    
        }
    
    }

    > #hambrgr {

        @include flex-basis(33%);
        // fallback for ie
        width: 33%;

        display: none;
        margin-left: 20px;

        @media screen and (max-width: $navigation-breakpoint) {
            display: block;
        }

        > span {
            width: 30px;
            height: 2px;
            background-color: $text-default-color;
            display: block;
            margin: 5px 0;
        }

    }

    > .logoContainer {

        @media screen and (max-width: $navigation-breakpoint) {
            @include flex-basis(33%);
            // fallback for ie
            width: 33%;
        }

    }

    button {
        
        @media screen and (max-width: $navigation-breakpoint) {

           margin-right: 20px;

        }

        @media screen and (max-width: 500px) {
            visibility: hidden;
        }

    }

    img {
        height: 100px;
    }

}