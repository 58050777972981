/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-12 16:17:03
 * @modify date 2018-12-12 16:17:03
 * @desc [description]
*/

@import '../../main';

.notifier {

    @include box-shadow(0 8px 33px 0 rgba(0, 0, 0, 0.25));
    @include translate(500px, 0);
    @include transition(transform 0.25s ease-in-out);

    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;

    padding: 15px;
    padding-right: 35px;
    border-radius: 10px;
    max-width: 250px;

    &.visible {
        @include translate(0, 0);
    }

    &.success {
        background-color: #27ae60;
    }

    &.error {
        background-color: indianred;
    }

    &.warning {
        background-color: palegoldenrod;
    }

    > span {
        color: white;

        &:nth-child(2) {
            position: absolute;
            top: 0;
            right: 10px;
            font-size: 20px;
            cursor: pointer;
        }

    }

}