/* loader by DOM @ahdigital */
/* https://codepen.io/ahdigital/ */
/* https://codepen.io/ahdigital/pen/prXBzN */
  
@import '../../main';

.preloader-2 {
margin: 120px auto 0;
}

.preloader-2 .line {
width: 1px;
height: 12px;
background: #fff;
margin: 0 1px;
display: inline-block;
animation: opacity-2 2000ms infinite ease-in-out;
}

.preloader-2 .line-1 { animation-delay: 800ms; }
.preloader-2 .line-2 { animation-delay: 600ms; }
.preloader-2 .line-3 { animation-delay: 400ms; }
.preloader-2 .line-4 { animation-delay: 200ms; }
.preloader-2 .line-6 { animation-delay: 200ms; }
.preloader-2 .line-7 { animation-delay: 400ms; }
.preloader-2 .line-8 { animation-delay: 600ms; }
.preloader-2 .line-9 { animation-delay: 800ms; }

@keyframes opacity-2 { 
0% { 
    opacity: 1;
    height: 15px;
}
50% { 
    opacity: 0;
    height: 14px;
}
100% { 
    opacity: 1;
    height: 15px;
}  
}

/* updated css */

.preloader-2 {

    @include flex;
    @include justify-content(center);
    @include align-items(center);

    margin: 0;

    > .line {
        margin: 0 3px;
    }


}
