/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:30:07
 * @modify date 2018-11-19 13:30:07
 * @desc [description]
*/

@import '../../main';

footer {

    @include flex;
    @include align-items(center);
    @include flex-direction(column);

    padding-top: 50px;
    padding-bottom: 0;

    > .title {

        @include flex;
        @include justify-content(center);
        @include align-items(center);
        @include flex-wrap(wrap);

        width: 100%;

        img {
            height: 100px;
        }

        > h1 {

            font-weight: 100;
            margin-left: 0px;
            font-size: 50px;
            color: #000;
            font-family: 'Catamaran', sans-serif;
            text-align: center;

        }

        @media screen and (max-width: 750px) {
        
            h1 {
                font-size: 35px;
            }
    
        }
    
        @media screen and (max-width: 500px) {
            
            h1 {
                font-size: 28px;
            }
    
    
        }
    
        @media screen and (max-width: 450) {
            
            h1 {
                font-size: 22px;
            }
    
    
        }

    }

    > .contents {

        @include flex;
        @include justify-content(space-around);
        @include flex-wrap(wrap);

        width: 100%;
        padding: 30px 10px;
        padding-bottom: 60px;
        box-sizing: border-box;

        > div {

            margin: 0 10px;

            @media screen and (max-width: 600px) {

                width: 100%;

                > ul {
                    @include flex;
                    @include flex-wrap(wrap);

                    > li {
                        padding-right: 10px !important;
                    }

                }

            }

            > h4 {
                color: $theme-orange;
            }

            > ul {
                margin: 0;
                padding: 0;

                > li {

                    @include flex;

                    list-style-type: none;
                    padding: 5px 0;

                    img {
                        margin-right: 7px;
                    }

                    > a {

                        @include flex;
                        @include align-items(center);

                        text-decoration: none;
                        color: inherit;

                    }

                }

            }

        }

    }


    > .gradient {

        width: 100%;
        height: 5px;
        margin-top:5px;

    }

}