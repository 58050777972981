/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-15 10:52:41
 * @modify date 2019-05-15 10:52:41
 * @desc [description]
*/

@import '../../../main';

p {
    text-align: left;
}

.registration {

    width: 50%;

    @media screen and (max-width: 1300px) {
        width: 65%;
    }

    @media screen and (max-width: 1110px) {
        width: 75%;
    }

    @media screen and (max-width: 500px) {
        width: 85%;
    }

    .dates {

        @include flex;
        @include flex-wrap(wrap);
        
        > .date-block {
    
            padding: 10px;
            border: $border-lightgray;
            margin-right: 10px;
            margin-bottom: 10px;
            min-width: 190px;
    
            &:last-child {
                margin-right: 0;
            }
    
            &.active {
                background-color: #4CD137;
                color: white;
    
                > h4 {
                    color: white;
                }
            }
            &.dormant {
                background-color: #FCFCFC;
                color: $theme-orange;
            }
            &.dead {
                background-color: #808080;
                color: #cecece;
    
                > h4 {
                    color: #cecece;
                }
            }
    
            > h4 {
                margin-top:0;
                margin-bottom: 0;
                font-size: 20px;
            }

            > .days {
                font-size: 12px;
                color: $text-default-color;
                margin-bottom: 20px;

                &.active {
                    color: white;
                }

            }

            > span {
                font-size: 12px;
                display: block;
            }
    
        }
    
    }

    form {

        margin-top: 30px;

        h4 {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: $border-lightgray;
        }
    
        .input-group {
    
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        
            > label {
                display: block;
                margin-left: 0;
                font-size: 13px;
            }
        
            > input, textarea, select {
                border-radius: 3px;
                margin: 5px 0 0;
            }
    
            > input {
                width: calc(100% - 26px);
            }

            > select {
                width: 100%;
                outline: $theme-blue;
            }
        
            > textarea {
                width: 100%;
                height: 100%;
                resize: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        
        }   
    
        button {
            @include box-shadow(none);
            margin-top: 0; 
            background-color: $theme-blue;
            color: white; 
            border-radius: 3px;
            margin: 0;
            min-height: 40px;
        }
        
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    rgba(0,0,0,0.45);
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
           color:    rgba(0,0,0,0.45);
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
           color:    rgba(0,0,0,0.45);
        }
    }
    
    ::placeholder { /* Most modern browsers support this now. */
       color:    rgba(0,0,0,0.45);
    }

}

.message.failure {

    padding: 15px;
    border-radius: 3px;
    border: $border-lightgray;
    background-color: rgba(255,0,0,0.04);
    width: 100%;

}