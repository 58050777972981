/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-28 16:31:51
 * @modify date 2018-11-28 16:31:51
 * @desc [description]
*/

@import '../../main';

p {

    @include flex;
    @include justify-content(space-between);
    @include flex-wrap(wrap);

    width: 50%;
    text-align: center;

    @media screen and (max-width: 1300px) {
        width: 65%;
    }

    @media screen and (max-width: 1110px) {
        width: 75%;
    }

    @media screen and (max-width: 500px) {
        width: 85%;
    }

}

.cardsWrapper  {

    @include flex;
    @include justify-content(center);
    @include flex-wrap(wrap);

    > .card {

        @include box-shadow(0 4px 10px 0 rgba(0,0,0,00.25)); 
    
        border-radius: 25px;
        text-align: center;
        max-width: 400px;
        margin: 20px;

        @media screen and (max-width: 1400px) {
            max-width: 350px;
        }

        @media screen and (max-width: 1250px) {
            max-width: 300px;
        }

        @media screen and (max-width: 1150px) {
            max-width: 280px;
        }

        @media screen and (max-width: 1000px) {
            max-width: 450px;
        }

        &:nth-child(1) {
            > .title {
                background-color: #FBAC7E;
            }
        }

        &:nth-child(2) {
            > .title {
                background-color: $theme-blue;;
            }
        }

        &:nth-child(3) {
            > .title {
                background-color: #61B872;
            }
        }
    
        > .title {
    
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            padding: 20px;
            color: white;
    
        }
    
        > .body  {
    
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            padding: 20px;

        }
    
    
    }

}

.aboutCEO {

    @include flex;
    @include justify-content(center);
    @include align-items(flex-start);

    width: 50%;
    margin-top: 40px;

    @media screen and (max-width: 1300px) {
        width: 65%;
    }

    @media screen and (max-width: 1110px) {
        width: 75%;
    }

    @media screen and (max-width: 1000px) {
        @include justify-content(center);
        @include flex-direction(column);
        @include align-items(center);

        > .imgWrapper {
            width: auto !important;
        }

        > .info {
            margin-left: 0 !important;
            margin-top: 10px;
        }

    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    > .imgWrapper {
    
        width: auto;

        > img {
            
            @include box-shadow(0 8px 50px gray);

            max-height: 300px;
            width: auto;

        }
    }

    > .info {

        margin-left: 50px;

        > p{

            text-align: left;
            width: 100%;

        }

    }

}

.involvementWrapper {

    @include flex;
    @include justify-content(center);
    @include align-items(flex-start);
    @include flex-wrap(wrap);

    width: 50%;
    margin-top: 40px;

    @media screen and (max-width: 1300px) {
        width: 65%;
    }

    @media screen and (max-width: 1110px) {
        width: 75%;
    }

    @media screen and (max-width: 1000px) {
        @include justify-content(center);
        @include flex-direction(column);
        @include align-items(center);

        > .imgWrapper {
            width: 75% !important;
        }

    }

    @media screen and (max-width: 500px) {
        width: 100%;

        > .imgWrapper > img {
            width: 100%;
        }
    }

    > .imgWrapper {
    
        width: 50%;
        position: relative;

        @media screen and (max-width: 750px){
            width: auto !important;
        }

        &:hover {

            > .infoWrapper {
                opacity: 1;
            }

        }

        > img {
            
            @include box-shadow(0 8px 50px gray);

            max-height: 300px;
            width: 100%;

        }

        > .infoWrapper {

            @include flex;
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);
            @include transition(opacity 0.335s ease-out);

            opacity: 0;
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            color: white;
            background-color: rgba(0,0,0,0.5);
            
            > .info {
                padding: 10px;
                border: 2px solid white;
                text-transform: uppercase;
                min-width: 100px;
            }

        }

    }

}