/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-26 16:09:41
 * @modify date 2018-11-26 16:09:41
 * @desc [description]
*/

@import '../../main';

[type="text"], [type="email"], [type="date"], [type="password"], [type="password"], [type="file"], textarea, select {

    @include transition(border 0.35s ease-in-out);

    padding: 12px;
    color: inherit;    
    border-radius: 3px;
    border: $border-lightgray;
    outline: none;
    
    &.error {
        border: 1px solid palevioletred;
    }

    &:focus {
        border: 1px solid $theme-blue;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(100,97,97,0.5);
        opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(100,97,97,0.5);
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: rgba(100,97,97,0.5);
    }

}

textarea {

    resize: vertical;
    height: 100px;
    box-sizing: border-box;

}

.checkboxWrapper {

    border: $border-lightgray;
    padding: 10px;
    min-width: 100px;
    text-align: center;
    margin: 5px;

    &.checked {
        background-color: $theme-blue;
    
        > label {
            color: white;
        }

    }

    > label {
        margin-right: 10px;
    }

}

// for containing div with class inputs
.inputs.error {

    border: 1px solid palevioletred;
    
    > span.error {
        width: 100%;
    }

}