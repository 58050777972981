/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-23 11:01:20
 * @modify date 2018-11-23 11:01:20
 * @desc [description]
*/

@import '../../../main';

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.roundedBackground {

    position: absolute;
    width: 70%;
    height: 100%;
    background-color: rgba(245, 154, 100, 0.1);
    right: 0;
    z-index: -1;
    border-radius: 400px 0 0 400px;

    @media screen and (max-width: 700px) {
                    
        width: 85%;

    }

}

.sliderContainer {

    min-width: 0;
    min-height: 0;
    width: 100%;

}

.sliderItem {

    min-width: 0;
    min-height: 0;
    width: 100%;

}

.client {

    @include flex;
    @include align-items(center);
    @include justify-content(center);
    @include flex-wrap(wrap);

    > div {

        @include flex-basis(35%);
        // fallback
        width: 35%;

        @media screen and (max-width: 1200px) {
            @include flex-basis(35%);
            // fallback
            width: 35%;
        }

        @media screen and (max-width: 1050) {
            @include flex-basis(40%);
            // fallback
            width: 40%;
        }

        @media screen and (max-width: 900px) {
            @include flex-basis(45%);
            // fallback
            width: 45%;
        }

        @media screen and (max-width: 750px) {
            @include flex-basis(50%);
            // fallback
            width: 50%;
        }

        @media screen and (max-width: 700px) {
            @include flex-grow(1);

            @include flex-basis(100%);
            // fallback
            width: 100%;
        }

        &.productWrapper {

            @include flex;
            @include justify-content(center);
            @include align-items(center);

            > a {
                @include flex;
                @include justify-content(center);
                @include align-items(center);
            
                > img {

                    @include drop-shadow(0 1px 7px gray);
    
                    width: 100%;
                    height: 100%;
    
                    @media screen and (max-width: 700px) {
                        
                        width: 60%;
                        height: 60%;
    
                    }
    
                }

            }

        }

        &.testimonialWrapper {

            @include flex;
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);

            @include flex-basis(35%);
            // fallback
            width: 35%;

            > .imageWrapper {

                > img {
                    border-radius: 50%;
                    margin-bottom: 15px;
                }

            }

            > .clientInfo {

                > div {

                    text-align: center;

                    &:nth-child(1) {
                        font-weight: bold;
                    }

                    &:nth-child(2) {
                        font-size: 14px;
                    }

                }

            }

            > .testimonial {

                text-align: center;
                margin: 15px 0;
                margin-top: 40px;

                > img {
                    position: relative;
                    top:-40px;
                }

            }

        }

    }

}