/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-27 13:46:53
 * @modify date 2018-11-27 13:46:53
 * @desc [description]
*/

@import '../../../main';

div.footerBlock {

    margin: 0 10px;

    @media screen and (max-width: 600px) {

        width: 100%;

        > ul {
            @include flex;
            @include flex-wrap(wrap);

            > li {
                padding-right: 10px !important;
            }

        }

    }

    > h4 {
        color: $theme-orange;
    }

    > ul {
        margin: 0;
        padding: 0;

        > li {

            @include flex;

            list-style-type: none;
            padding: 5px 0;

            img {
                margin-right: 7px;
            }

            > a {

                @include flex;
                @include align-items(center);

                text-decoration: none;
                color: inherit;

            }

        }

    }

}