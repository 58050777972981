/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-03 16:46:42
 * @modify date 2018-12-03 16:46:42
 * @desc [description]
*/

@import '../../main';

.clientWrapper {

    @include flex;
    @include justify-content(space-between);
    @include align-items(center);

    width: 50%;
    margin-top: 40px;

    @media screen and (max-width: 1300px) {
        width: 65%;
    }

    @media screen and (max-width: 1110px) {
        width: 75%;
    }

    @media screen and (max-width: 1000px) {
        @include justify-content(center);
        @include flex-direction(column);
        @include align-items(center);

        > div {
            width: 100% !important;
        }

        > .clientInfo {
            margin-left: 0 !important;
            margin-top: 10px;
        }

    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    > div {
        width: 50%;

        > img {
            
            width: 95%;

            &.boxShadow {
                @include box-shadow(0 8px 50px gray);
            }
        }
    }

    > .clientInfo {

        margin-left: 50px;

        > p{

            text-align: left;
            width: 100%;

        }

        > .service {

            display: inline-block;
            background-color: $theme-blue;
            padding: 6px 0; 
            color: white;
            min-width: 100px;
            text-align: center;
            border-radius: 25px;
            font-size: 13px;

            &:nth-of-type(n+2) {
                margin-left: 10px;
            }

        }

    }

}