/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 16:41:36
 * @modify date 2018-11-19 16:41:36
 * @desc [description]
*/

@import '../../main';

section {

    @include flex;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(space-around);

    padding: 30px 20px 50px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    &.standardHeight {
        min-height: 500px;
    }

    > h1 {

        @include flex;
        @include flex-direction(column);
        @include align-items(center);

        font-weight: 100;
        margin-bottom: 40px;
        font-size: 2vw;

        @media screen and (max-width: 1100px) {
            font-size: 24px;
        }

        @media screen and (max-width: 400px) {
            font-size: 20px;
        }

        > .underline {

            height: 2px;
            width: 115%;
            margin-top: 20px;

        }
    }

}