/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-27 13:50:15
 * @modify date 2018-11-27 13:50:15
 * @desc [description]
*/

@import '../../main';

div.contactSection {

    @include flex;
    @include justify-content(center);
    @include flex-wrap(wrap);

    width: 50%;

    @media screena and (max-width: 1500px) {
        width: 75%;
    }

    @media screen and (max-width: 1400px) {
        width: 80%;
    }

    @media screen and (max-width: 1300px) {
        width: 85%;
    }

    @media screen and (max-width: 1110px) {
        width: 85%;
    }

    @media screen and (max-width: 1000px) {

        > .line {
            display: none;
        }

    }

    @media screen and (max-width: 500px) {
        width: 85%;
    }

    > .contactInfo {

        @media screen and (max-width: 965px) {
            @include flex;
            @include flex-wrap(wrap);

            margin-bottom: 30px;

        }

    }

    > .line {

        width: 1px;
        margin: 0 10px;
        background-color: $border-color-lightgray;

    }

}