/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-20 11:21:00
 * @modify date 2018-11-20 11:21:00
 * @desc [description]
*/

@import '../../../main';

.serviceItemsWrapper {

    @include flex;
    @include justify-content(center);
    @include align-items(center);
    @include flex-wrap(wrap);

    padding: 40px 0;

    > .serviceItem {

        @include flex;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);

        max-width: 400px;
        margin: 40px 20px;

        @media screen and (max-width: 1400px) {
            max-width: 320px;
        }

        @media screen and (max-width: 1200px) {
            max-width: 280px;
        }

        @media screen and (max-width: 1000px) {
            max-width: 220px;
        }

        @media screen and (max-width: 860px) {
            min-width: 400px;
        }

        @media screen and (max-width: 500px) {
            min-width: 220px;
        }

        .description {
            text-align: center;
            min-height: 40px;
        }

        > h4 {
            text-align: center;
        }

    }

    svg {

        @include drop-shadow(1px 1px 17px gray);

    }

}