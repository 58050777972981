/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 14:14:23
 * @modify date 2018-11-16 14:14:23
 * @desc [description]
*/

@import '../../main';

.header {

    @include flex;
    @include flex-direction(column);

    > .topBar {

        @include flex;
        @include justify-content(center);

        border-bottom: 1px solid rgba(229,229,229,0.5);
        padding: 10px;
        font-size: 14px;

        @media screen and (max-width: $navigation-breakpoint) {
            padding-right: 10px;
        }

        > div {

            @include flex;

            padding: 0 20px;

            @media screen and (max-width: 500px) {
                @include scale(0.9);
                padding: 0;
            }

            > img {
                margin-right: 5px;
            }

        }

    }

    > .skewedSection {

        position: relative;
        padding: 50px 0;
        overflow: hidden;

        > .overlay {
        
            position: absolute;
            z-index: 1;
            top:0;
            background-color: rgba(0,0,0,0.5);
            height: 100%;
            width: 100%;

        }


        > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top:0;
        }


    }

}