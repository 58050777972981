/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-03 12:55:20
 * @modify date 2018-12-03 12:55:20
 * @desc [description]
*/

@import '../../../main';

.tabContainer {

    @include flex;
    @include box-shadow(0 16px 50px 0 rgba(0,0,0,0.25));

    border-radius: 25px;
    margin-top:20px;
    background-color: #fff; 
    width: 50%;

    @media screen and (max-width: 1300px) {
        width: 70%;
    }

    @media screen and (max-width: 1000px) {

        @include flex-direction(column);
        width: 80%;

    }

    @media screen and (max-width: 600px) {

        width: 90%;

    }

    @media screen and (max-width: 500px) {

        width: 100%;

    }

    

    > .tabTitles {

        background-color: $theme-blue;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;

        min-width: 220px;

        @media screen and (max-width: 1000px) {

            border-top-right-radius: 25px;
            border-bottom-left-radius: 0;

        }

        >  ul {

            margin: 0;
            padding: 0;

            @media screen and (max-width: 1000px) {

                @include flex;
                @include justify-content(center);

            }

            > li {

                @include flex;
                @include align-items(center);
                @include justify-content(center);
                @include flex-direction(column);

                list-style-type: none;
                padding: 30px;
                text-align: center;

                &.active {
                    
                    position: relative;

                    &:after {
                        content: "";
                        border-width: 10px;
                        border-color: transparent;
                        border-style: solid;
                        border-right-color: white;
                        position: absolute;
                        right: -1px;
                        top: calc(50% - 10px);
                    }

                    @media screen and (max-width: 1000px) {
                        &:after {
                            border-right-color: transparent;
                            border-bottom-color: white;
                            right: calc(50% - 10px);
                            bottom: 0;
                            top: initial;
                        }

                    }
                }

                @media screen and (max-width: 500px) {

                    padding: 20px;
            
                }

                > img {

                    height: 60px;
                    margin-bottom: 5px;

                    @media screen and (max-width: 700px) {

                        height: 45px;
            
                    }

                    @media screen and (max-width: 600px) {

                        height: 30px;
            
                    }

                }

                > span {
                    color: white;
                    font-size: 15px;

                    @media screen and (max-width: 1000px) {

                        display: none;
            
                    }

                }

            }

        }

    }

    > .tabContents {

        @include flex;
        @include justify-content(center);
        @include align-items(center);

        min-width: 400px;
        max-width: 700px;

        @media screen and (max-width: 1000px) {

            min-width: 0;
            min-height: 250px;

        }

        > .tabContent {

            @include flex-direction(column);
            @include flex-wrap(wrap);
            @include justify-content(flex-start);
            @include align-items(center);

            display: none;

            &.active {
                @include flex;
            }

            > h3 {
                font-weight: 200;
            }

            > div {

                > img {

                    height: 60px;
                    margin: 25px;
    
                    @media screen and (max-width: 700px) {
    
                        @include flex-direction(row);
                        height: 45px;
            
                    }
    
                    @media screen and (max-width: 600px) {
    
                        height: 30px;
            
                    }
    
                }

            }

        }

    }

}