/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/

$theme-blue: #34BFF3;
$theme-orange: #F46C24;
$theme-orange-dim: #FBAC7E;
$theme-background: #fff;
$border-color-lightgray: rgba(0,0,0,0.10);
$border-lightgray: 1px solid $border-color-lightgray;
$text-default-color: #646161;

$navigation-breakpoint: 845px;

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100');

@mixin flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin flexImp{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

@mixin justify-content($alignment) {
    -webkit-box-pack: $alignment;
        -ms-flex-pack: $alignment;
            justify-content: $alignment;
}

@mixin align-items($alignment ) {
    -webkit-box-align: $alignment;
        -ms-flex-align: $alignment;
            align-items: $alignment;
}

@mixin align-self($alignment) {
    -ms-flex-item-align: $alignment;
        align-self: $alignment;
}

@mixin justify-self($alignment) {
    justify-self: $alignment;
}

@mixin flex-direction($direction) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: $direction;
            flex-direction: $direction;
}

@mixin flex-grow($growBy) {
    -webkit-box-flex: $growBy;
        -ms-flex-positive: $growBy;
            flex-grow: $growBy;
}

@mixin flex-shrink($shrinkBy) {
    -ms-flex-negative: $shrinkBy;
        flex-shrink: $shrinkBy;
}

@mixin flex-basis($basis) {
    -ms-flex-preferred-size: $basis;
        flex-basis: $basis;
}

@mixin flex-wrap($wrap) {
    -ms-flex-wrap: $wrap;
        flex-wrap: $wrap;
}

@mixin order($order) {
    -webkit-box-ordinal-group: $order;
        -ms-flex-order: $order;
            order: $order;
}

@mixin box-shadow($shadowArgs) {
    -webkit-box-shadow: $shadowArgs;
            box-shadow: $shadowArgs;
}

@mixin rotate($rotateBy) {
    -webkit-transform: rotate($rotateBy);
            transform: rotate($rotateBy);
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
            transform: translate($x, $y);
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin scale($scaleBy, $important:"") {
    -webkit-transform: scale($scaleBy) unquote($important);
            transform: scale($scaleBy) unquote($important);    
}

@mixin linear-gradient($args...) {

    background-image: -webkit-gradient($args);

    background-image: -webkit-gradient(linear, left top, left bottom, from($args));

    background-image: linear-gradient($args);

}

@mixin drop-shadow($args) {

    -webkit-filter: drop-shadow($args);
            filter: drop-shadow($args);

}

/* CSS specific to iOS devices */ 
@supports (-webkit-overflow-scrolling: touch) {

    input {
        border-radius: 0;
        -webkit-border-radius: 0;
        -webkit-appearance: none;
    }

}

html, body {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', sans-serif;
    background-color: $theme-background;
    color: $text-default-color;

    &.fixed {
        position: fixed;
    }

}

button {
    outline: none;

    &:hover {
        cursor: pointer;
    }

}

.gradient {

    @include linear-gradient(25deg, $theme-blue, $theme-orange);

}

.overlay {
    opacity: 0.8;
}

div {
    outline: none;
}

.label {

    padding: 0 15px;
    border-radius: 30px;
    color: white;
    font-weight: light;
    background-color: $theme-orange-dim;
    font-size: 12px;
    line-height: 2;

    &.primary {
        background-color: $theme-blue;
    }

}

a {
    text-decoration: none;
    color: inherit;
}

span.error {
    margin-left: 5px;
    font-size: 12px;
    color: palevioletred;
}