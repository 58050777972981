/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-19 11:04:23
 * @modify date 2018-12-19 11:04:23
 * @desc [description]
*/

@import '../../main';

.container {

    @include flex;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);

    min-height: 500px;

    > h1 {
        color: $theme-orange;
        font-size: 20vh;
        margin: 0;

        > span {
            color: $theme-blue;
        }

    }

    > .message {
        text-align: center;

        width: 40%;

        @media screen and (max-width: 700px) {
            width: 50%;
        }
        @media screen and (max-width: 600px) {
            width: 60%;
        }
        @media screen and (max-width: 500px) {
            width: 70%;
        }
        @media screen and (max-width: 400px) {
            width: 80%;
        }
        @media screen and (max-width: 300px) {
            width: 90%;
        }

    }

}