/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-27 11:50:46
 * @modify date 2018-11-27 11:50:46
 * @desc [description]
*/

@import '../../../main';

form {

    @include flex;
    @include flex-direction(column);

    > input, textarea {

        min-width: 400px;
        padding-left: 12px;
        margin: 5px;
        font-family: inherit;
        color: inherit;

        @media screen and (max-width: 450px) {
            min-width: 250px;
        }
        
    }

    > button {

        padding: 12px;
        border-radius: 3px;
        margin: 10px 5px 5px;

    }

}