/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-16 10:37:26
 * @modify date 2018-12-16 10:37:26
 * @desc [description]
*/

.slider {
    width: 70%;

    @media screen and (max-width: 600px) {
        width: 80%;
    }
    @media screen and (max-width: 500px) {
        width: 90%;
    }
    @media screen and (max-width: 400px) {
        width: 95%;
    }

    .iconWrapper {

        margin: 0 25px;

        img {
            height: 100px;
            width: auto;
            margin: auto;
        }

        h5 {
            text-align: center;
        }

    }

}