/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-04 16:04:57
 * @modify date 2018-12-04 16:04:57
 * @desc [description]
*/

label {
    margin-left: 8px;
}