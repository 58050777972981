@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:31:36
 * @modify date 2018-11-16 12:31:36
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

button {
  box-shadow: 0 8px 33px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 35px;
  border: none;
  border-radius: 20px; }
  button.primary {
    color: #fff;
    background-color: #34BFF3; }
  button.secondary {
    background-color: #fff;
    color: inherit; }
  button.orange {
    color: #fff;
    background-color: #FBAC7E; }
  button.large {
    min-width: 250px;
    padding: 15px 20px;
    border-radius: 50px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 13:27:27
 * @modify date 2018-11-16 13:27:27
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.navContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }
  @media screen and (max-width: 845px) {
    .navContainer {
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  @media screen and (max-width: 845px) {
    .navContainer > nav {
      -webkit-transition: max-width 0.25s ease-out;
      transition: max-width 0.25s ease-out;
      max-width: 0;
      position: absolute;
      top: 105px;
      z-index: 3;
      background-color: white;
      width: 60%;
      height: calc(100vh - 154px);
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.1);
      border-width: 0;
      overflow: hidden; }
      .navContainer > nav.visible {
        max-width: 60%;
        border-width: 1px;
        border-left-width: 0; }
      .navContainer > nav li > a:before {
        -webkit-transform: scale(1) !important;
        transform: scale(1) !important;
        visibility: visible !important;
        height: 1px !important;
        background-color: rgba(0, 0, 0, 0.1) !important; }
      .navContainer > nav li > a.active {
        color: #34BFF3; }
      .navContainer > nav li > a:last-child:before {
        -webkit-transform: scale(1) !important;
        transform: scale(1) !important;
        visibility: visible !important;
        height: 0px !important;
        background-color: rgba(0, 0, 0, 0.1) !important; } }
  .navContainer > nav > ul {
    display: -webkit-flex;
    display: flex;
    padding: 0;
    margin: 0; }
    @media screen and (max-width: 845px) {
      .navContainer > nav > ul {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    .navContainer > nav > ul > li {
      list-style-type: none;
      position: relative; }
      .navContainer > nav > ul > li > a {
        color: #646161;
        text-decoration: none;
        padding: 10px 30px;
        display: inline-block; }
        .navContainer > nav > ul > li > a.active:before {
          -webkit-transform: scale(1) ;
          transform: scale(1) ;
          visibility: visible; }
        .navContainer > nav > ul > li > a:hover:before {
          -webkit-transform: scale(1) ;
          transform: scale(1) ;
          visibility: visible; }
        .navContainer > nav > ul > li > a:before {
          -webkit-transform: scale(0) ;
          transform: scale(0) ;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          content: "";
          display: block;
          height: 2px;
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          background-color: #34BFF3;
          visibility: hidden; }
  .navContainer > #hambrgr {
    -webkit-flex-basis: 33%;
            flex-basis: 33%;
    width: 33%;
    display: none;
    margin-left: 20px; }
    @media screen and (max-width: 845px) {
      .navContainer > #hambrgr {
        display: block; } }
    .navContainer > #hambrgr > span {
      width: 30px;
      height: 2px;
      background-color: #646161;
      display: block;
      margin: 5px 0; }
  @media screen and (max-width: 845px) {
    .navContainer > .logoContainer {
      -webkit-flex-basis: 33%;
              flex-basis: 33%;
      width: 33%; } }
  @media screen and (max-width: 845px) {
    .navContainer button {
      margin-right: 20px; } }
  @media screen and (max-width: 500px) {
    .navContainer button {
      visibility: hidden; } }
  .navContainer img {
    height: 100px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 16:56:07
 * @modify date 2018-11-16 16:56:07
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

section.titleSection {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  z-index: 1; }
  section.titleSection > h1 {
    font-weight: 100;
    font-size: 50px;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    text-align: center; }
  section.titleSection > div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    section.titleSection > div > span {
      font-size: 25px;
      margin-bottom: 25px;
      color: white; }
    section.titleSection > div button {
      padding: 20px;
      border-radius: 50px;
      min-width: 200px;
      margin: 10px; }
  @media screen and (max-width: 750px) {
    section.titleSection > h1 {
      font-size: 35px; }
    section.titleSection > div > span {
      font-size: 20px;
      padding: 10px;
      margin-bottom: 10px; }
    section.titleSection > div button {
      padding: 10px;
      min-width: 100px;
      margin: 5px; } }
  @media screen and (max-width: 500px) {
    section.titleSection > h1 {
      font-size: 28px; }
    section.titleSection > div > span {
      font-size: 18px;
      padding: 10px;
      margin-bottom: 10px; }
    section.titleSection > div button {
      padding: 10px;
      min-width: 100px;
      margin: 5px; } }
  @media screen and (max-width: 450) {
    section.titleSection > h1 {
      font-size: 22px; }
    section.titleSection > div > span {
      font-size: 18px;
      padding: 10px;
      margin-bottom: 10px; }
    section.titleSection > div button {
      padding: 10px;
      min-width: 100px;
      margin: 5px; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 16:41:36
 * @modify date 2018-11-19 16:41:36
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 30px 20px 50px;
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  section.standardHeight {
    min-height: 500px; }
  section > h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 100;
    margin-bottom: 40px;
    font-size: 2vw; }
    @media screen and (max-width: 1100px) {
      section > h1 {
        font-size: 24px; } }
    @media screen and (max-width: 400px) {
      section > h1 {
        font-size: 20px; } }
    section > h1 > .underline {
      height: 2px;
      width: 115%;
      margin-top: 20px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-20 11:21:00
 * @modify date 2018-11-20 11:21:00
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.serviceItemsWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 40px 0; }
  .serviceItemsWrapper > .serviceItem {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 400px;
    margin: 40px 20px; }
    @media screen and (max-width: 1400px) {
      .serviceItemsWrapper > .serviceItem {
        max-width: 320px; } }
    @media screen and (max-width: 1200px) {
      .serviceItemsWrapper > .serviceItem {
        max-width: 280px; } }
    @media screen and (max-width: 1000px) {
      .serviceItemsWrapper > .serviceItem {
        max-width: 220px; } }
    @media screen and (max-width: 860px) {
      .serviceItemsWrapper > .serviceItem {
        min-width: 400px; } }
    @media screen and (max-width: 500px) {
      .serviceItemsWrapper > .serviceItem {
        min-width: 220px; } }
    .serviceItemsWrapper > .serviceItem .description {
      text-align: center;
      min-height: 40px; }
    .serviceItemsWrapper > .serviceItem > h4 {
      text-align: center; }
  .serviceItemsWrapper svg {
    -webkit-filter: drop-shadow(1px 1px 17px gray);
    filter: drop-shadow(1px 1px 17px gray); }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-21 12:09:12
 * @modify date 2018-11-21 12:09:12
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.aboutWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .aboutWrapper p {
    text-align: center;
    margin-bottom: 20px;
    width: 50%; }
    @media screen and (max-width: 800px) {
      .aboutWrapper p {
        width: 60%; } }
    @media screen and (max-width: 700px) {
      .aboutWrapper p {
        width: 70%; } }
    @media screen and (max-width: 600px) {
      .aboutWrapper p {
        width: 80%; } }
    @media screen and (max-width: 500px) {
      .aboutWrapper p {
        width: 90%; } }
    @media screen and (max-width: 400px) {
      .aboutWrapper p {
        width: 95%; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-22 12:17:36
 * @modify date 2018-11-22 12:17:36
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.stepsWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media screen and (max-width: 950px) {
    .stepsWrapper {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; } }
  .stepsWrapper > .text {
    margin-bottom: 40px;
    font-weight: bold; }
    @media screen and (max-width: 950px) {
      .stepsWrapper > .text {
        margin: 0; } }
  .stepsWrapper > .step {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-width: 400px;
    margin: 40px 20px; }
    @media screen and (max-width: 1500px) {
      .stepsWrapper > .step {
        min-width: 320px; } }
    @media screen and (max-width: 1250px) {
      .stepsWrapper > .step {
        min-width: 220px; } }
    @media screen and (max-width: 950px) {
      .stepsWrapper > .step {
        min-width: 400px;
        margin: 20px; } }
    @media screen and (max-width: 500px) {
      .stepsWrapper > .step {
        min-width: 220px; } }
    .stepsWrapper > .step:first-child .horizontal {
      position: relative;
      left: 40px; }
    .stepsWrapper > .step:last-child .horizontal {
      position: relative;
      right: 40px; }
    .stepsWrapper > .step > div > .title {
      text-transform: uppercase;
      margin: 20px 0;
      text-align: center; }
    .stepsWrapper > .step > .horizontal {
      display: block; }
      @media screen and (max-width: 950px) {
        .stepsWrapper > .step > .horizontal {
          display: none; } }
    .stepsWrapper > .step > .vertical {
      display: none; }
      @media screen and (max-width: 950px) {
        .stepsWrapper > .step > .vertical {
          display: block; } }
  .stepsWrapper svg {
    -webkit-filter: drop-shadow(1px 1px 17px gray);
    filter: drop-shadow(1px 1px 17px gray); }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-23 11:01:20
 * @modify date 2018-11-23 11:01:20
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.roundedBackground {
  position: absolute;
  width: 70%;
  height: 100%;
  background-color: rgba(245, 154, 100, 0.1);
  right: 0;
  z-index: -1;
  border-radius: 400px 0 0 400px; }
  @media screen and (max-width: 700px) {
    .roundedBackground {
      width: 85%; } }

.sliderContainer {
  min-width: 0;
  min-height: 0;
  width: 100%; }

.sliderItem {
  min-width: 0;
  min-height: 0;
  width: 100%; }

.client {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .client > div {
    -webkit-flex-basis: 35%;
            flex-basis: 35%;
    width: 35%; }
    @media screen and (max-width: 1200px) {
      .client > div {
        -webkit-flex-basis: 35%;
                flex-basis: 35%;
        width: 35%; } }
    @media screen and (max-width: 1050) {
      .client > div {
        -webkit-flex-basis: 40%;
                flex-basis: 40%;
        width: 40%; } }
    @media screen and (max-width: 900px) {
      .client > div {
        -webkit-flex-basis: 45%;
                flex-basis: 45%;
        width: 45%; } }
    @media screen and (max-width: 750px) {
      .client > div {
        -webkit-flex-basis: 50%;
                flex-basis: 50%;
        width: 50%; } }
    @media screen and (max-width: 700px) {
      .client > div {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        width: 100%; } }
    .client > div.productWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .client > div.productWrapper > a {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; }
        .client > div.productWrapper > a > img {
          -webkit-filter: drop-shadow(0 1px 7px gray);
          filter: drop-shadow(0 1px 7px gray);
          width: 100%;
          height: 100%; }
          @media screen and (max-width: 700px) {
            .client > div.productWrapper > a > img {
              width: 60%;
              height: 60%; } }
    .client > div.testimonialWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-basis: 35%;
              flex-basis: 35%;
      width: 35%; }
      .client > div.testimonialWrapper > .imageWrapper > img {
        border-radius: 50%;
        margin-bottom: 15px; }
      .client > div.testimonialWrapper > .clientInfo > div {
        text-align: center; }
        .client > div.testimonialWrapper > .clientInfo > div:nth-child(1) {
          font-weight: bold; }
        .client > div.testimonialWrapper > .clientInfo > div:nth-child(2) {
          font-size: 14px; }
      .client > div.testimonialWrapper > .testimonial {
        text-align: center;
        margin: 15px 0;
        margin-top: 40px; }
        .client > div.testimonialWrapper > .testimonial > img {
          position: relative;
          top: -40px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-16 10:37:26
 * @modify date 2018-12-16 10:37:26
 * @desc [description]
*/
.slider {
  width: 70%; }
  @media screen and (max-width: 600px) {
    .slider {
      width: 80%; } }
  @media screen and (max-width: 500px) {
    .slider {
      width: 90%; } }
  @media screen and (max-width: 400px) {
    .slider {
      width: 95%; } }
  .slider .iconWrapper {
    margin: 0 25px; }
    .slider .iconWrapper img {
      height: 100px;
      width: auto;
      margin: auto; }
    .slider .iconWrapper h5 {
      text-align: center; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-12 16:17:03
 * @modify date 2018-12-12 16:17:03
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.notifier {
  box-shadow: 0 8px 33px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate(500px, 0);
  transform: translate(500px, 0);
  -webkit-transition: transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
  padding: 15px;
  padding-right: 35px;
  border-radius: 10px;
  max-width: 250px; }
  .notifier.visible {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  .notifier.success {
    background-color: #27ae60; }
  .notifier.error {
    background-color: indianred; }
  .notifier.warning {
    background-color: palegoldenrod; }
  .notifier > span {
    color: white; }
    .notifier > span:nth-child(2) {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 20px;
      cursor: pointer; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-04 12:07:27
 * @modify date 2018-12-04 12:07:27
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.modalWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-transform: translate(0, -120vh);
  transform: translate(0, -120vh);
  -webkit-transition: transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 1; }
  .modalWrapper.visible {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  .modalWrapper > .modal {
    background-color: white;
    padding: 15px;
    margin: 100px 0; }
    @media screen and (max-width: 850px) {
      .modalWrapper > .modal {
        width: 90%; } }
    @media screen and (max-width: 450px) {
      .modalWrapper > .modal {
        width: 80%; } }
    .modalWrapper > .modal > .closeIcon {
      font-size: 20px;
      float: right;
      cursor: pointer; }
    .modalWrapper > .modal > .titleWrapper {
      color: #34BFF3; }
      .modalWrapper > .modal > .titleWrapper > h3 {
        margin-top: 5px;
        color: #646161; }
    @media screen and (max-width: 500px) {
      .modalWrapper > .modal input, .modalWrapper > .modal textarea {
        min-width: 200px; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-26 16:09:41
 * @modify date 2018-11-26 16:09:41
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

[type="text"], [type="email"], [type="date"], [type="password"], [type="password"], [type="file"], textarea, select {
  -webkit-transition: border 0.35s ease-in-out;
  transition: border 0.35s ease-in-out;
  padding: 12px;
  color: inherit;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: none; }
  [type="text"].error, [type="email"].error, [type="date"].error, [type="password"].error, [type="password"].error, [type="file"].error, textarea.error, select.error {
    border: 1px solid palevioletred; }
  [type="text"]:focus, [type="email"]:focus, [type="date"]:focus, [type="password"]:focus, [type="password"]:focus, [type="file"]:focus, textarea:focus, select:focus {
    border: 1px solid #34BFF3; }
  [type="text"] ::-webkit-input-placeholder, [type="email"] ::-webkit-input-placeholder, [type="date"] ::-webkit-input-placeholder, [type="password"] ::-webkit-input-placeholder, [type="password"] ::-webkit-input-placeholder, [type="file"] ::-webkit-input-placeholder, textarea ::-webkit-input-placeholder, select ::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(100, 97, 97, 0.5);
    opacity: 1;
    /* Firefox */ }
  [type="text"] ::-ms-input-placeholder, [type="email"] ::-ms-input-placeholder, [type="date"] ::-ms-input-placeholder, [type="password"] ::-ms-input-placeholder, [type="password"] ::-ms-input-placeholder, [type="file"] ::-ms-input-placeholder, textarea ::-ms-input-placeholder, select ::-ms-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(100, 97, 97, 0.5);
    opacity: 1;
    /* Firefox */ }
  [type="text"] ::placeholder, [type="email"] ::placeholder, [type="date"] ::placeholder, [type="password"] ::placeholder, [type="password"] ::placeholder, [type="file"] ::placeholder, textarea ::placeholder, select ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(100, 97, 97, 0.5);
    opacity: 1;
    /* Firefox */ }
  [type="text"] :-ms-input-placeholder, [type="email"] :-ms-input-placeholder, [type="date"] :-ms-input-placeholder, [type="password"] :-ms-input-placeholder, [type="password"] :-ms-input-placeholder, [type="file"] :-ms-input-placeholder, textarea :-ms-input-placeholder, select :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(100, 97, 97, 0.5); }
  [type="text"] ::-ms-input-placeholder, [type="email"] ::-ms-input-placeholder, [type="date"] ::-ms-input-placeholder, [type="password"] ::-ms-input-placeholder, [type="password"] ::-ms-input-placeholder, [type="file"] ::-ms-input-placeholder, textarea ::-ms-input-placeholder, select ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(100, 97, 97, 0.5); }

textarea {
  resize: vertical;
  height: 100px;
  box-sizing: border-box; }

.checkboxWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 100px;
  text-align: center;
  margin: 5px; }
  .checkboxWrapper.checked {
    background-color: #34BFF3; }
    .checkboxWrapper.checked > label {
      color: white; }
  .checkboxWrapper > label {
    margin-right: 10px; }

.inputs.error {
  border: 1px solid palevioletred; }
  .inputs.error > span.error {
    width: 100%; }

/* loader by DOM @ahdigital */
/* https://codepen.io/ahdigital/ */
/* https://codepen.io/ahdigital/pen/prXBzN */
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.preloader-2 {
  margin: 120px auto 0; }

.preloader-2 .line {
  width: 1px;
  height: 12px;
  background: #fff;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: opacity-2 2000ms infinite ease-in-out;
          animation: opacity-2 2000ms infinite ease-in-out; }

.preloader-2 .line-1 {
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms; }

.preloader-2 .line-2 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms; }

.preloader-2 .line-3 {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms; }

.preloader-2 .line-4 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms; }

.preloader-2 .line-6 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms; }

.preloader-2 .line-7 {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms; }

.preloader-2 .line-8 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms; }

.preloader-2 .line-9 {
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms; }

@-webkit-keyframes opacity-2 {
  0% {
    opacity: 1;
    height: 15px; }
  50% {
    opacity: 0;
    height: 14px; }
  100% {
    opacity: 1;
    height: 15px; } }

@keyframes opacity-2 {
  0% {
    opacity: 1;
    height: 15px; }
  50% {
    opacity: 0;
    height: 14px; }
  100% {
    opacity: 1;
    height: 15px; } }

/* updated css */
.preloader-2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 0; }
  .preloader-2 > .line {
    margin: 0 3px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-05 12:48:02
 * @modify date 2018-12-05 12:48:02
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

form#quoteForm {
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 750px;
  min-height: 500px; }
  @media screen and (max-width: 850px) {
    form#quoteForm {
      width: 100%;
      min-width: 0; } }
  form#quoteForm > .tabTitles {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto; }
    form#quoteForm > .tabTitles > ul {
      display: -webkit-flex;
      display: flex;
      margin: 0;
      padding: 0; }
      form#quoteForm > .tabTitles > ul > li {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin: 0;
        padding: 10px 35px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        list-style-type: none;
        cursor: pointer; }
        form#quoteForm > .tabTitles > ul > li svg {
          height: 15px;
          margin-left: 3px; }
        form#quoteForm > .tabTitles > ul > li:last-child {
          border-right: none; }
        form#quoteForm > .tabTitles > ul > li.active {
          border-bottom: 2px solid #34BFF3;
          background-color: rgba(0, 0, 0, 0.05); }
  form#quoteForm > .tabContents {
    padding: 20px; }
    form#quoteForm > .tabContents > .tabContent {
      display: none; }
      form#quoteForm > .tabContents > .tabContent.active {
        display: block; }
      form#quoteForm > .tabContents > .tabContent > .formCategory > .inputs {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        margin: 20px 0; }
        form#quoteForm > .tabContents > .tabContent > .formCategory > .inputs.row {
          -webkit-flex-direction: row;
                  flex-direction: row; }
      form#quoteForm > .tabContents > .tabContent > .formCategory .checkboxWrapper > input {
        min-width: 0 !important; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 14:14:23
 * @modify date 2018-11-16 14:14:23
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .header > .topBar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: 1px solid rgba(229, 229, 229, 0.5);
    padding: 10px;
    font-size: 14px; }
    @media screen and (max-width: 845px) {
      .header > .topBar {
        padding-right: 10px; } }
    .header > .topBar > div {
      display: -webkit-flex;
      display: flex;
      padding: 0 20px; }
      @media screen and (max-width: 500px) {
        .header > .topBar > div {
          -webkit-transform: scale(0.9) ;
          transform: scale(0.9) ;
          padding: 0; } }
      .header > .topBar > div > img {
        margin-right: 5px; }
  .header > .skewedSection {
    position: relative;
    padding: 50px 0;
    overflow: hidden; }
    .header > .skewedSection > .overlay {
      position: absolute;
      z-index: 1;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%; }
    .header > .skewedSection > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-27 13:46:53
 * @modify date 2018-11-27 13:46:53
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

div.footerBlock {
  margin: 0 10px; }
  @media screen and (max-width: 600px) {
    div.footerBlock {
      width: 100%; }
      div.footerBlock > ul {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
        div.footerBlock > ul > li {
          padding-right: 10px !important; } }
  div.footerBlock > h4 {
    color: #F46C24; }
  div.footerBlock > ul {
    margin: 0;
    padding: 0; }
    div.footerBlock > ul > li {
      display: -webkit-flex;
      display: flex;
      list-style-type: none;
      padding: 5px 0; }
      div.footerBlock > ul > li img {
        margin-right: 7px; }
      div.footerBlock > ul > li > a {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        text-decoration: none;
        color: inherit; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-23 17:17:39
 * @modify date 2018-11-23 17:17:39
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.messageWrapper {
  position: relative;
  overflow: hidden; }
  .messageWrapper > img {
    position: absolute;
    width: 100%;
    height: auto;
    z-index: -2; }
    @media screen and (max-width: 840px) {
      .messageWrapper > img {
        height: 100%;
        width: auto; } }
    @media screen and (max-width: 600px) {
      .messageWrapper > img {
        object-position: -100px; } }
    @media screen and (max-width: 600px) {
      .messageWrapper > img {
        object-position: -220px; } }
    @media screen and (max-width: 400px) {
      .messageWrapper > img {
        object-position: -300px; } }
  .messageWrapper > .overlay {
    background-image: -webkit-gradient(to right, #412A11, rgba(240, 185, 125, 0));
    background-image: -webkit-gradient(linear, left top, left bottom, from(to right, #412A11, rgba(240, 185, 125, 0)));
    background-image: -webkit-linear-gradient(left, #412A11, rgba(240, 185, 125, 0));
    background-image: linear-gradient(to right, #412A11, rgba(240, 185, 125, 0));
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .messageWrapper > .message {
    padding: 50px 20px; }
    @media screen and (max-width: 1000px) {
      .messageWrapper > .message {
        padding: 25px 20px;
        padding-bottom: 0px; } }
    .messageWrapper > .message > h1 {
      text-transform: uppercase;
      color: white;
      font-size: 3vw;
      text-shadow: 0 1px 1px black; }
      @media screen and (max-width: 1000px) {
        .messageWrapper > .message > h1 {
          font-size: 35px; } }
      @media screen and (max-width: 700px) {
        .messageWrapper > .message > h1 {
          font-size: 30px; } }
  .messageWrapper > .btnWrapper {
    padding: 20px; }
    .messageWrapper > .btnWrapper a {
      color: inherit; }
    .messageWrapper > .btnWrapper button {
      width: 200px;
      margin-right: 7px;
      padding-top: 15px;
      padding-bottom: 15px;
      border-radius: 100px; }
      @media screen and (max-width: 1000px) {
        .messageWrapper > .btnWrapper button {
          width: 180px; } }
      @media screen and (max-width: 750px) {
        .messageWrapper > .btnWrapper button {
          padding-top: 10px;
          padding-bottom: 10px;
          width: 150px; } }
      @media screen and (max-width: 360px) {
        .messageWrapper > .btnWrapper button {
          width: 120px;
          font-size: 10px; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-19 13:30:07
 * @modify date 2018-11-19 13:30:07
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 50px;
  padding-bottom: 0; }
  footer > .title {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%; }
    footer > .title img {
      height: 100px; }
    footer > .title > h1 {
      font-weight: 100;
      margin-left: 0px;
      font-size: 50px;
      color: #000;
      font-family: 'Catamaran', sans-serif;
      text-align: center; }
    @media screen and (max-width: 750px) {
      footer > .title h1 {
        font-size: 35px; } }
    @media screen and (max-width: 500px) {
      footer > .title h1 {
        font-size: 28px; } }
    @media screen and (max-width: 450) {
      footer > .title h1 {
        font-size: 22px; } }
  footer > .contents {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    padding: 30px 10px;
    padding-bottom: 60px;
    box-sizing: border-box; }
    footer > .contents > div {
      margin: 0 10px; }
      @media screen and (max-width: 600px) {
        footer > .contents > div {
          width: 100%; }
          footer > .contents > div > ul {
            display: -webkit-flex;
            display: flex;
            -webkit-flex-wrap: wrap;
                    flex-wrap: wrap; }
            footer > .contents > div > ul > li {
              padding-right: 10px !important; } }
      footer > .contents > div > h4 {
        color: #F46C24; }
      footer > .contents > div > ul {
        margin: 0;
        padding: 0; }
        footer > .contents > div > ul > li {
          display: -webkit-flex;
          display: flex;
          list-style-type: none;
          padding: 5px 0; }
          footer > .contents > div > ul > li img {
            margin-right: 7px; }
          footer > .contents > div > ul > li > a {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            text-decoration: none;
            color: inherit; }
  footer > .gradient {
    width: 100%;
    height: 5px;
    margin-top: 5px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-27 11:50:46
 * @modify date 2018-11-27 11:50:46
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  form > input, form textarea {
    min-width: 400px;
    padding-left: 12px;
    margin: 5px;
    font-family: inherit;
    color: inherit; }
    @media screen and (max-width: 450px) {
      form > input, form textarea {
        min-width: 250px; } }
  form > button {
    padding: 12px;
    border-radius: 3px;
    margin: 10px 5px 5px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-27 13:50:15
 * @modify date 2018-11-27 13:50:15
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

div.contactSection {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 50%; }
  @media screena and (max-width: 1500px) {
    div.contactSection {
      width: 75%; } }
  @media screen and (max-width: 1400px) {
    div.contactSection {
      width: 80%; } }
  @media screen and (max-width: 1300px) {
    div.contactSection {
      width: 85%; } }
  @media screen and (max-width: 1110px) {
    div.contactSection {
      width: 85%; } }
  @media screen and (max-width: 1000px) {
    div.contactSection > .line {
      display: none; } }
  @media screen and (max-width: 500px) {
    div.contactSection {
      width: 85%; } }
  @media screen and (max-width: 965px) {
    div.contactSection > .contactInfo {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      margin-bottom: 30px; } }
  div.contactSection > .line {
    width: 1px;
    margin: 0 10px;
    background-color: rgba(0, 0, 0, 0.1); }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-03 12:55:20
 * @modify date 2018-12-03 12:55:20
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.tabContainer {
  display: -webkit-flex;
  display: flex;
  box-shadow: 0 16px 50px 0 rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-top: 20px;
  background-color: #fff;
  width: 50%; }
  @media screen and (max-width: 1300px) {
    .tabContainer {
      width: 70%; } }
  @media screen and (max-width: 1000px) {
    .tabContainer {
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 80%; } }
  @media screen and (max-width: 600px) {
    .tabContainer {
      width: 90%; } }
  @media screen and (max-width: 500px) {
    .tabContainer {
      width: 100%; } }
  .tabContainer > .tabTitles {
    background-color: #34BFF3;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    min-width: 220px; }
    @media screen and (max-width: 1000px) {
      .tabContainer > .tabTitles {
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0; } }
    .tabContainer > .tabTitles > ul {
      margin: 0;
      padding: 0; }
      @media screen and (max-width: 1000px) {
        .tabContainer > .tabTitles > ul {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center; } }
      .tabContainer > .tabTitles > ul > li {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
                flex-direction: column;
        list-style-type: none;
        padding: 30px;
        text-align: center; }
        .tabContainer > .tabTitles > ul > li.active {
          position: relative; }
          .tabContainer > .tabTitles > ul > li.active:after {
            content: "";
            border-width: 10px;
            border-color: transparent;
            border-style: solid;
            border-right-color: white;
            position: absolute;
            right: -1px;
            top: calc(50% - 10px); }
          @media screen and (max-width: 1000px) {
            .tabContainer > .tabTitles > ul > li.active:after {
              border-right-color: transparent;
              border-bottom-color: white;
              right: calc(50% - 10px);
              bottom: 0;
              top: auto;
              top: initial; } }
        @media screen and (max-width: 500px) {
          .tabContainer > .tabTitles > ul > li {
            padding: 20px; } }
        .tabContainer > .tabTitles > ul > li > img {
          height: 60px;
          margin-bottom: 5px; }
          @media screen and (max-width: 700px) {
            .tabContainer > .tabTitles > ul > li > img {
              height: 45px; } }
          @media screen and (max-width: 600px) {
            .tabContainer > .tabTitles > ul > li > img {
              height: 30px; } }
        .tabContainer > .tabTitles > ul > li > span {
          color: white;
          font-size: 15px; }
          @media screen and (max-width: 1000px) {
            .tabContainer > .tabTitles > ul > li > span {
              display: none; } }
  .tabContainer > .tabContents {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    min-width: 400px;
    max-width: 700px; }
    @media screen and (max-width: 1000px) {
      .tabContainer > .tabContents {
        min-width: 0;
        min-height: 250px; } }
    .tabContainer > .tabContents > .tabContent {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      -webkit-align-items: center;
              align-items: center;
      display: none; }
      .tabContainer > .tabContents > .tabContent.active {
        display: -webkit-flex;
        display: flex; }
      .tabContainer > .tabContents > .tabContent > h3 {
        font-weight: 200; }
      .tabContainer > .tabContents > .tabContent > div > img {
        height: 60px;
        margin: 25px; }
        @media screen and (max-width: 700px) {
          .tabContainer > .tabContents > .tabContent > div > img {
            -webkit-flex-direction: row;
                    flex-direction: row;
            height: 45px; } }
        @media screen and (max-width: 600px) {
          .tabContainer > .tabContents > .tabContent > div > img {
            height: 30px; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-28 16:31:51
 * @modify date 2018-11-28 16:31:51
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

p {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 50%;
  text-align: center; }
  @media screen and (max-width: 1300px) {
    p {
      width: 65%; } }
  @media screen and (max-width: 1110px) {
    p {
      width: 75%; } }
  @media screen and (max-width: 500px) {
    p {
      width: 85%; } }

.cardsWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .cardsWrapper > .card {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    text-align: center;
    max-width: 400px;
    margin: 20px; }
    @media screen and (max-width: 1400px) {
      .cardsWrapper > .card {
        max-width: 350px; } }
    @media screen and (max-width: 1250px) {
      .cardsWrapper > .card {
        max-width: 300px; } }
    @media screen and (max-width: 1150px) {
      .cardsWrapper > .card {
        max-width: 280px; } }
    @media screen and (max-width: 1000px) {
      .cardsWrapper > .card {
        max-width: 450px; } }
    .cardsWrapper > .card:nth-child(1) > .title {
      background-color: #FBAC7E; }
    .cardsWrapper > .card:nth-child(2) > .title {
      background-color: #34BFF3; }
    .cardsWrapper > .card:nth-child(3) > .title {
      background-color: #61B872; }
    .cardsWrapper > .card > .title {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      padding: 20px;
      color: white; }
    .cardsWrapper > .card > .body {
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      padding: 20px; }

.aboutCEO {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 50%;
  margin-top: 40px; }
  @media screen and (max-width: 1300px) {
    .aboutCEO {
      width: 65%; } }
  @media screen and (max-width: 1110px) {
    .aboutCEO {
      width: 75%; } }
  @media screen and (max-width: 1000px) {
    .aboutCEO {
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; }
      .aboutCEO > .imgWrapper {
        width: auto !important; }
      .aboutCEO > .info {
        margin-left: 0 !important;
        margin-top: 10px; } }
  @media screen and (max-width: 500px) {
    .aboutCEO {
      width: 100%; } }
  .aboutCEO > .imgWrapper {
    width: auto; }
    .aboutCEO > .imgWrapper > img {
      box-shadow: 0 8px 50px gray;
      max-height: 300px;
      width: auto; }
  .aboutCEO > .info {
    margin-left: 50px; }
    .aboutCEO > .info > p {
      text-align: left;
      width: 100%; }

.involvementWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 50%;
  margin-top: 40px; }
  @media screen and (max-width: 1300px) {
    .involvementWrapper {
      width: 65%; } }
  @media screen and (max-width: 1110px) {
    .involvementWrapper {
      width: 75%; } }
  @media screen and (max-width: 1000px) {
    .involvementWrapper {
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; }
      .involvementWrapper > .imgWrapper {
        width: 75% !important; } }
  @media screen and (max-width: 500px) {
    .involvementWrapper {
      width: 100%; }
      .involvementWrapper > .imgWrapper > img {
        width: 100%; } }
  .involvementWrapper > .imgWrapper {
    width: 50%;
    position: relative; }
    @media screen and (max-width: 750px) {
      .involvementWrapper > .imgWrapper {
        width: auto !important; } }
    .involvementWrapper > .imgWrapper:hover > .infoWrapper {
      opacity: 1; }
    .involvementWrapper > .imgWrapper > img {
      box-shadow: 0 8px 50px gray;
      max-height: 300px;
      width: 100%; }
    .involvementWrapper > .imgWrapper > .infoWrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-transition: opacity 0.335s ease-out;
      transition: opacity 0.335s ease-out;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: white;
      background-color: rgba(0, 0, 0, 0.5); }
      .involvementWrapper > .imgWrapper > .infoWrapper > .info {
        padding: 10px;
        border: 2px solid white;
        text-transform: uppercase;
        min-width: 100px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-04 16:04:57
 * @modify date 2018-12-04 16:04:57
 * @desc [description]
*/
label {
  margin-left: 8px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

#openPositionsTabContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 50%; }
  #openPositionsTabContainer::-webkit-scrollbar {
    width: 8px; }
  #openPositionsTabContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px; }
  #openPositionsTabContainer::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.7); }
  @media screen and (max-width: 1600px) {
    #openPositionsTabContainer {
      width: 60%; } }
  @media screen and (max-width: 1400px) {
    #openPositionsTabContainer {
      width: 70%; } }
  @media screen and (max-width: 1200px) {
    #openPositionsTabContainer {
      width: 80%; } }
  @media screen and (max-width: 1000px) {
    #openPositionsTabContainer {
      width: 90%; } }
  @media screen and (max-width: 750px) {
    #openPositionsTabContainer {
      width: 75%;
      border-right: none; } }
  @media screen and (max-width: 600px) {
    #openPositionsTabContainer {
      width: 90%; } }
  #openPositionsTabContainer > .openPositionsList {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-width: 250px;
    max-width: 250px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 576px;
    overflow: auto; }
    #openPositionsTabContainer > .openPositionsList::-webkit-scrollbar {
      width: 5px; }
    #openPositionsTabContainer > .openPositionsList::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0px; }
    #openPositionsTabContainer > .openPositionsList::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: rgba(0, 0, 0, 0.3); }
    @media screen and (max-width: 750px) {
      #openPositionsTabContainer > .openPositionsList {
        max-width: 750px; } }
    #openPositionsTabContainer > .openPositionsList > h4 {
      background-color: #EBEBEB;
      margin: 0;
      padding: 10px;
      font-weight: 100; }
    #openPositionsTabContainer > .openPositionsList > .tabTitles > ul {
      margin: 0;
      padding: 0; }
      #openPositionsTabContainer > .openPositionsList > .tabTitles > ul > li {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 15px;
        list-style-type: none; }
        #openPositionsTabContainer > .openPositionsList > .tabTitles > ul > li.active {
          background-color: #34BFF3 !important;
          color: white;
          border-right: 1px solid #34BFF3;
          border-left: 1px solid #34BFF3;
          position: relative; }
          @media screen and (min-width: 750px) {
            #openPositionsTabContainer > .openPositionsList > .tabTitles > ul > li.active:after {
              content: "";
              border-width: 10px;
              border-color: transparent;
              border-style: solid;
              border-right-color: white;
              position: absolute;
              right: -1px;
              top: calc(50% - 10px); } }
        #openPositionsTabContainer > .openPositionsList > .tabTitles > ul > li:nth-child(even) {
          background-color: #F6F2F2; }
  #openPositionsTabContainer > .tabContentsContainer {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    @media screen and (max-width: 750px) {
      #openPositionsTabContainer > .tabContentsContainer {
        -webkit-transition: transform 0.3s ease-in;
        -webkit-transition: -webkit-transform 0.3s ease-in;
        transition: -webkit-transform 0.3s ease-in;
        transition: transform 0.3s ease-in;
        transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
        box-shadow: 0 15px 60px 0 rgba(0, 0, 0, 0.6);
        -webkit-transform: translate(-750px, 0);
        transform: translate(-750px, 0);
        position: fixed;
        top: 0;
        left: 0;
        width: 95%;
        height: 100vh;
        background-color: white;
        z-index: 1;
        overflow: auto; }
        #openPositionsTabContainer > .tabContentsContainer.active {
          -webkit-transform: translate(0, 0);
          transform: translate(0, 0); } }
    #openPositionsTabContainer > .tabContentsContainer > button {
      -webkit-align-items: center;
              align-items: center;
      box-shadow: none;
      display: none;
      margin: 15px; }
      @media screen and (max-width: 750px) {
        #openPositionsTabContainer > .tabContentsContainer > button {
          display: -webkit-flex;
          display: flex; } }
      #openPositionsTabContainer > .tabContentsContainer > button svg {
        height: 20px; }
      #openPositionsTabContainer > .tabContentsContainer > button > span:not(.isvg) {
        margin-left: 20px; }
    #openPositionsTabContainer > .tabContentsContainer > .tabContent {
      padding: 20px;
      display: none;
      max-height: 500px;
      overflow: auto; }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent::-webkit-scrollbar {
        width: 5px; }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 0px; }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: rgba(0, 0, 0, 0.3); }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent.active {
        display: block; }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent > h2 {
        font-weight: 100;
        margin-top: 0; }
        #openPositionsTabContainer > .tabContentsContainer > .tabContent > h2 > span {
          display: inline-block;
          margin-left: 20px; }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent > span.label {
        width: 65px;
        display: inline-block;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 5px; }
      #openPositionsTabContainer > .tabContentsContainer > .tabContent > .jobDetails {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
        #openPositionsTabContainer > .tabContentsContainer > .tabContent > .jobDetails > div {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          margin: 10px; }
          #openPositionsTabContainer > .tabContentsContainer > .tabContent > .jobDetails > div > ol {
            margin: 0;
            padding: 0; }
            #openPositionsTabContainer > .tabContentsContainer > .tabContent > .jobDetails > div > ol > li {
              margin: 0;
              padding: 10px; }
              #openPositionsTabContainer > .tabContentsContainer > .tabContent > .jobDetails > div > ol > li:nth-child(odd) {
                background-color: rgba(196, 196, 196, 0.1); }
    #openPositionsTabContainer > .tabContentsContainer > .buttonsContainer {
      padding: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
      @media screen and (max-width: 380px) {
        #openPositionsTabContainer > .tabContentsContainer > .buttonsContainer > button {
          padding: 10px 15px !important; } }
      #openPositionsTabContainer > .tabContentsContainer > .buttonsContainer > button:nth-child(2) {
        margin-left: 10px; }

.share > div {
  margin: 5px 0; }
  .share > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 150px; }
  .share > div > button {
    box-shadow: none;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    color: inherit;
    min-width: 100px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-30 12:07:28
 * @modify date 2018-11-30 12:07:28
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.valuesWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 40px 0; }
  .valuesWrapper > .value {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 400px;
    margin: 40px 20px; }
    @media screen and (max-width: 1400px) {
      .valuesWrapper > .value {
        max-width: 320px; } }
    @media screen and (max-width: 1200px) {
      .valuesWrapper > .value {
        max-width: 280px; } }
    @media screen and (max-width: 1000px) {
      .valuesWrapper > .value {
        max-width: 220px; } }
    @media screen and (max-width: 860px) {
      .valuesWrapper > .value {
        min-width: 400px; } }
    @media screen and (max-width: 500px) {
      .valuesWrapper > .value {
        min-width: 220px; } }
    .valuesWrapper > .value .description {
      text-align: center; }
    .valuesWrapper > .value > h4 {
      text-align: center; }
  .valuesWrapper svg {
    -webkit-filter: drop-shadow(1px 1px 17px gray);
    filter: drop-shadow(1px 1px 17px gray);
    height: 100px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-03 16:46:42
 * @modify date 2018-12-03 16:46:42
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.clientWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 50%;
  margin-top: 40px; }
  @media screen and (max-width: 1300px) {
    .clientWrapper {
      width: 65%; } }
  @media screen and (max-width: 1110px) {
    .clientWrapper {
      width: 75%; } }
  @media screen and (max-width: 1000px) {
    .clientWrapper {
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; }
      .clientWrapper > div {
        width: 100% !important; }
      .clientWrapper > .clientInfo {
        margin-left: 0 !important;
        margin-top: 10px; } }
  @media screen and (max-width: 500px) {
    .clientWrapper {
      width: 100%; } }
  .clientWrapper > div {
    width: 50%; }
    .clientWrapper > div > img {
      width: 95%; }
      .clientWrapper > div > img.boxShadow {
        box-shadow: 0 8px 50px gray; }
  .clientWrapper > .clientInfo {
    margin-left: 50px; }
    .clientWrapper > .clientInfo > p {
      text-align: left;
      width: 100%; }
    .clientWrapper > .clientInfo > .service {
      display: inline-block;
      background-color: #34BFF3;
      padding: 6px 0;
      color: white;
      min-width: 100px;
      text-align: center;
      border-radius: 25px;
      font-size: 13px; }
      .clientWrapper > .clientInfo > .service:nth-of-type(n+2) {
        margin-left: 10px; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-19 11:04:23
 * @modify date 2018-12-19 11:04:23
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 500px; }
  .container > h1 {
    color: #F46C24;
    font-size: 20vh;
    margin: 0; }
    .container > h1 > span {
      color: #34BFF3; }
  .container > .message {
    text-align: center;
    width: 40%; }
    @media screen and (max-width: 700px) {
      .container > .message {
        width: 50%; } }
    @media screen and (max-width: 600px) {
      .container > .message {
        width: 60%; } }
    @media screen and (max-width: 500px) {
      .container > .message {
        width: 70%; } }
    @media screen and (max-width: 400px) {
      .container > .message {
        width: 80%; } }
    @media screen and (max-width: 300px) {
      .container > .message {
        width: 90%; } }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-12 15:31:23
 * @modify date 2019-05-12 15:31:23
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.feedback-btn {
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #34BFF3 !important;
  color: white;
  padding: 15px 0;
  width: 200px;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  left: 30px;
  border-radius: 10px;
  z-index: 2; }
  @media screen and (max-width: 600px) {
    .feedback-btn {
      border-radius: 0 10px 10px 0;
      left: 0;
      width: 120px; } }

.feedback-form-wrapper {
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  padding: 0px 10px;
  background-color: white;
  z-index: 3;
  border-radius: 5px;
  bottom: 85px;
  left: 30px;
  overflow-y: auto; }
  .feedback-form-wrapper.open {
    max-height: 1000px;
    padding: 10px; }
  @media screen and (max-width: 600px) {
    .feedback-form-wrapper {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      bottom: 0;
      left: 0;
      border-radius: 0; } }
  @media screen and (max-height: 700px) {
    .feedback-form-wrapper {
      height: calc(80%); } }
  @media screen and (max-height: 600px) {
    .feedback-form-wrapper {
      height: calc(100% - 20px);
      bottom: 0;
      left: 0;
      border-radius: 0; } }
  .feedback-form-wrapper h4 {
    margin: 0 -10px 0 -10px;
    padding: 10px;
    padding-top: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .feedback-form-wrapper h4 .close {
      float: right;
      cursor: pointer;
      position: relative;
      top: -3px;
      font-size: 20px; }
  .feedback-form-wrapper .input-group:not(:last-child) {
    margin-bottom: 10px; }
  .feedback-form-wrapper .input-group > label {
    display: block;
    margin-left: 0;
    font-size: 13px; }
  .feedback-form-wrapper .input-group > input, .feedback-form-wrapper .input-group textarea {
    border-radius: 3px;
    margin: 5px 0 0; }
  .feedback-form-wrapper .input-group > input {
    width: calc(100% - 26px); }
  .feedback-form-wrapper .input-group > textarea {
    width: 100%;
    height: 100%;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .feedback-form-wrapper button {
    box-shadow: none;
    margin-top: 0;
    background-color: #34BFF3;
    color: white;
    border-radius: 3px;
    margin: 0;
    min-height: 40px; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(0, 0, 0, 0.45); }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.45); }

::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(0, 0, 0, 0.45); }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-14 12:39:53
 * @modify date 2019-05-14 12:39:53
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

.courses {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 50px 0; }
  .courses .card {
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.15);
    padding: 15px;
    color: white;
    height: 400px;
    min-width: 250px; }
    @media screen and (max-width: 1050px) {
      .courses .card {
        min-width: 200px; } }
    @media screen and (max-width: 850px) {
      .courses .card {
        min-width: 190px; } }
    .courses .card.active {
      background-color: #4CD137;
      color: white; }
      .courses .card.active h4 {
        color: white; }
    .courses .card.dormant {
      background-color: #FCFCFC;
      color: #F46C24; }
    .courses .card.dead {
      background-color: #808080;
      color: white; }
      .courses .card.dead h4 {
        color: white; }
  .courses .course {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    -webkit-transition: all 0.125s ease-out;
    transition: all 0.125s ease-out;
    position: relative;
    max-width: 420px;
    margin-right: 30px;
    cursor: pointer; }
    .courses .course:nth-child(3n) {
      margin-right: 0; }
    .courses .course:hover {
      -webkit-transform: scale(1.05) ;
      transform: scale(1.05) ; }
    @media screen and (min-width: 800px) {
      .courses .course {
        min-width: 350px; } }
    @media screen and (max-width: 800px) {
      .courses .course {
        -webkit-flex-basis: 100%;
                flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 30px;
        max-width: auto; } }
    .courses .course .date {
      font-size: 25px;
      font-weight: bold; }
    .courses .course h4 {
      margin-top: 0;
      color: #646161;
      height: 50px; }
    .courses .course p {
      width: 100%;
      text-align: left;
      height: 160px;
      overflow: auto;
      padding: 15px 5px 15px 0;
      font-size: 14px; }
      .courses .course p::-webkit-scrollbar {
        width: 8px; }
      .courses .course p::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 3px; }
      .courses .course p::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.7); }
    .courses .course span {
      position: absolute;
      bottom: 25px;
      font-weight: bold; }

/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-15 10:52:41
 * @modify date 2019-05-15 10:52:41
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: -webkit-linear-gradient(65deg, #34BFF3, #F46C24);
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

p {
  text-align: left; }

.registration {
  width: 50%; }
  @media screen and (max-width: 1300px) {
    .registration {
      width: 65%; } }
  @media screen and (max-width: 1110px) {
    .registration {
      width: 75%; } }
  @media screen and (max-width: 500px) {
    .registration {
      width: 85%; } }
  .registration .dates {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .registration .dates > .date-block {
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      margin-bottom: 10px;
      min-width: 190px; }
      .registration .dates > .date-block:last-child {
        margin-right: 0; }
      .registration .dates > .date-block.active {
        background-color: #4CD137;
        color: white; }
        .registration .dates > .date-block.active > h4 {
          color: white; }
      .registration .dates > .date-block.dormant {
        background-color: #FCFCFC;
        color: #F46C24; }
      .registration .dates > .date-block.dead {
        background-color: #808080;
        color: #cecece; }
        .registration .dates > .date-block.dead > h4 {
          color: #cecece; }
      .registration .dates > .date-block > h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 20px; }
      .registration .dates > .date-block > .days {
        font-size: 12px;
        color: #646161;
        margin-bottom: 20px; }
        .registration .dates > .date-block > .days.active {
          color: white; }
      .registration .dates > .date-block > span {
        font-size: 12px;
        display: block; }
  .registration form {
    margin-top: 30px; }
    .registration form h4 {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .registration form .input-group:not(:last-child) {
      margin-bottom: 10px; }
    .registration form .input-group > label {
      display: block;
      margin-left: 0;
      font-size: 13px; }
    .registration form .input-group > input, .registration form .input-group textarea, .registration form .input-group select {
      border-radius: 3px;
      margin: 5px 0 0; }
    .registration form .input-group > input {
      width: calc(100% - 26px); }
    .registration form .input-group > select {
      width: 100%;
      outline: #34BFF3; }
    .registration form .input-group > textarea {
      width: 100%;
      height: 100%;
      resize: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    .registration form button {
      box-shadow: none;
      margin-top: 0;
      background-color: #34BFF3;
      color: white;
      border-radius: 3px;
      margin: 0;
      min-height: 40px; }
    .registration form ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(0, 0, 0, 0.45); }
    .registration form ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.45); }
  .registration ::-webkit-input-placeholder {
    /* Most modern browsers support this now. */
    color: rgba(0, 0, 0, 0.45); }
  .registration ::-ms-input-placeholder {
    /* Most modern browsers support this now. */
    color: rgba(0, 0, 0, 0.45); }
  .registration ::placeholder {
    /* Most modern browsers support this now. */
    color: rgba(0, 0, 0, 0.45); }

.message.failure {
  padding: 15px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 0, 0, 0.04);
  width: 100%; }

