/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 16:56:07
 * @modify date 2018-11-16 16:56:07
 * @desc [description]
*/

@import '../../main';

section.titleSection {

    @include flex;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);

    position: relative;
    z-index: 1;

    > h1 {
        font-weight: 100;
        font-size: 50px;
        color: white;
        text-shadow: 0 1px 1px rgba(0,0,0,0.5);
        text-align: center;
    }

    > div {
        @include flex;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);

        > span {
            font-size: 25px;
            margin-bottom: 25px;
            color: white;
        }

        button {
            padding: 20px;
            border-radius: 50px;
            min-width: 200px;
            margin: 10px;
        }

    }

    @media screen and (max-width: 750px) {
        
        > h1 {
            font-size: 35px;
        }

        > div {

            > span {
                font-size: 20px;
                padding: 10px;
                margin-bottom: 10px;
            }

            button {
                padding: 10px;
                min-width: 100px;
                margin: 5px;
            }

        }

    }

    @media screen and (max-width: 500px) {
        
        > h1 {
            font-size: 28px;
        }

        > div {

            > span {
                font-size: 18px;
                padding: 10px;
                margin-bottom: 10px;
            }

            button {
                padding: 10px;
                min-width: 100px;
                margin: 5px;
            }

        }

    }

    @media screen and (max-width: 450) {
        
        > h1 {
            font-size: 22px;
        }

        > div {

            > span {
                font-size: 18px;
                padding: 10px;
                margin-bottom: 10px;
            }

            button {
                padding: 10px;
                min-width: 100px;
                margin: 5px;
            }

        }

    }

}