/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-05-12 15:31:23
 * @modify date 2019-05-12 15:31:23
 * @desc [description]
*/

@import '../../main';

.feedback-btn {

    @include box-shadow(0 3px 12px 0 rgba(0,0,0,0.15));

    background-color: $theme-blue !important;
    color: white;
    padding: 15px 0;
    width: 200px;
    cursor: pointer;
    position: fixed;
    bottom: 30px;
    left: 30px;
    border-radius: 10px;
    z-index: 2;

    @media screen and (max-width: 600px) {
        // border-radius: 10px 0 0 10px;
        border-radius: 0 10px 10px 0;
        left: 0;
        width: 120px;
    }

}

.feedback-form-wrapper {

    @include box-shadow(0 3px 13px 0 rgba(0,0,0,0.15));
    @include transition(all 0.25s ease-out);

    &.open {
        max-height: 1000px;
        padding: 10px;
    }

    max-height: 0;
    overflow: hidden;
    position: fixed;
    padding: 0px 10px;
    background-color: white;
    z-index: 3;
    border-radius: 5px;
    bottom: 85px;
    left: 30px;
    overflow-y: auto; 

    @media screen and (max-width: 600px) {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        bottom:0;
        left: 0;
        border-radius: 0;
    }

    @media screen and (max-height: 700px) {
        height: calc(80%);
    }

    @media screen and (max-height: 600px) {
        height: calc(100% - 20px);
        bottom:0;
        left: 0;
        border-radius: 0;
    }

    h4 {
        margin: 0 -10px 0 -10px;
        padding: 10px;
        padding-top: 0;
        margin-bottom: 10px;
        border-bottom: $border-lightgray;
    
        .close {
            float: right;
            cursor: pointer;
            position: relative;
            top: -3px;
            font-size: 20px;
        }
    }

    .input-group {

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    
        > label {
            display: block;
            margin-left: 0;
            font-size: 13px;
        }
    
        > input, textarea {
            border-radius: 3px;
            margin: 5px 0 0;
        }

        > input {
            width: calc(100% - 26px);
        }
    
        > textarea {
            width: 100%;
            height: 100%;
            resize: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    
    }   

    button {
        @include box-shadow(none);
        margin-top: 0; 
        background-color: $theme-blue;
        color: white; 
        border-radius: 3px;
        margin: 0;
        min-height: 40px;
    }

}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    rgba(0,0,0,0.45);
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    rgba(0,0,0,0.45);
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    rgba(0,0,0,0.45);
}

::placeholder { /* Most modern browsers support this now. */
   color:    rgba(0,0,0,0.45);
}