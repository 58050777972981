/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:31:36
 * @modify date 2018-11-16 12:31:36
 * @desc [description]
*/

@import "../../main";

button {

    @include box-shadow(0 8px 33px 0 rgba(0,0,0,0.25));

    padding: 10px 35px;
    border: none;
    border-radius:20px;

    &.primary {
        color: #fff;
        background-color: $theme-blue;
    }

    &.secondary {
        background-color: #fff;
        color: inherit;
    }

    &.orange {
        color: #fff;
        background-color: $theme-orange-dim;
    }

    &.large {
        min-width: 250px;
        padding: 15px 20px;
        border-radius: 50px;
    }

}