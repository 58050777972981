/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-26 16:09:41
 * @modify date 2018-11-26 16:09:41
 * @desc [description]
*/
/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-16 12:34:08
 * @modify date 2018-11-16 12:34:08
 * @desc [description]
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Catamaran:100");
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none; } }

html, body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
  color: #646161; }
  body.fixed {
    position: fixed; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.gradient {
  background-image: -webkit-gradient(25deg, #34BFF3, #F46C24);
  background-image: -webkit-gradient(linear, left top, left bottom, from(25deg, #34BFF3, #F46C24));
  background-image: linear-gradient(25deg, #34BFF3, #F46C24); }

.overlay {
  opacity: 0.8; }

div {
  outline: none; }

.label {
  padding: 0 15px;
  border-radius: 30px;
  color: white;
  font-weight: light;
  background-color: #FBAC7E;
  font-size: 12px;
  line-height: 2; }
  .label.primary {
    background-color: #34BFF3; }

a {
  text-decoration: none;
  color: inherit; }

span.error {
  margin-left: 5px;
  font-size: 12px;
  color: palevioletred; }

[type="text"], [type="email"], [type="date"], [type="password"], [type="password"], [type="file"], textarea, select {
  -webkit-transition: border 0.35s ease-in-out;
  transition: border 0.35s ease-in-out;
  padding: 12px;
  color: inherit;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: none; }
  [type="text"].error, [type="email"].error, [type="date"].error, [type="password"].error, [type="password"].error, [type="file"].error, textarea.error, select.error {
    border: 1px solid palevioletred; }
  [type="text"]:focus, [type="email"]:focus, [type="date"]:focus, [type="password"]:focus, [type="password"]:focus, [type="file"]:focus, textarea:focus, select:focus {
    border: 1px solid #34BFF3; }
  [type="text"] ::placeholder, [type="email"] ::placeholder, [type="date"] ::placeholder, [type="password"] ::placeholder, [type="password"] ::placeholder, [type="file"] ::placeholder, textarea ::placeholder, select ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(100, 97, 97, 0.5);
    opacity: 1;
    /* Firefox */ }
  [type="text"] :-ms-input-placeholder, [type="email"] :-ms-input-placeholder, [type="date"] :-ms-input-placeholder, [type="password"] :-ms-input-placeholder, [type="password"] :-ms-input-placeholder, [type="file"] :-ms-input-placeholder, textarea :-ms-input-placeholder, select :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(100, 97, 97, 0.5); }
  [type="text"] ::-ms-input-placeholder, [type="email"] ::-ms-input-placeholder, [type="date"] ::-ms-input-placeholder, [type="password"] ::-ms-input-placeholder, [type="password"] ::-ms-input-placeholder, [type="file"] ::-ms-input-placeholder, textarea ::-ms-input-placeholder, select ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(100, 97, 97, 0.5); }

textarea {
  resize: vertical;
  height: 100px;
  box-sizing: border-box; }

.checkboxWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 100px;
  text-align: center;
  margin: 5px; }
  .checkboxWrapper.checked {
    background-color: #34BFF3; }
    .checkboxWrapper.checked > label {
      color: white; }
  .checkboxWrapper > label {
    margin-right: 10px; }

.inputs.error {
  border: 1px solid palevioletred; }
  .inputs.error > span.error {
    width: 100%; }
