/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-21 12:09:12
 * @modify date 2018-11-21 12:09:12
 * @desc [description]
*/

@import '../../../main';

.aboutWrapper {

    @include flex;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);

    p {
        text-align: center;
        margin-bottom: 20px;
        width: 50%;

        @media screen and (max-width: 800px) {
            width: 60%;
        }
        @media screen and (max-width: 700px) {
            width: 70%;
        }
        @media screen and (max-width: 600px) {
            width: 80%;
        }
        @media screen and (max-width: 500px) {
            width: 90%;
        }
        @media screen and (max-width: 400px) {
            width: 95%;
        }

    }

}