/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-23 17:17:39
 * @modify date 2018-11-23 17:17:39
 * @desc [description]
*/

@import '../../../main';

.messageWrapper {

    position: relative;
    overflow: hidden;

    > img {

        position: absolute;
        width: 100%;
        height: auto;
        z-index: -2;

        @media screen and (max-width: 840px) {
            height: 100%;
            width: auto;
        }

        @media screen and (max-width: 600px) {
            object-position: -100px;
        }
        @media screen and (max-width: 600px) {
            object-position: -220px;
        }
        @media screen and (max-width: 400px) {
            object-position: -300px;
        }



    }

    > .overlay {

        @include linear-gradient(to right, #412A11, rgba(240, 185, 125,0));

        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    
    }

    > .message {

        padding: 50px 20px;

        @media screen and (max-width: 1000px) {

            padding: 25px 20px;
            padding-bottom: 0px;

        }

        > h1 {

            text-transform: uppercase;
            color: white;
            font-size: 3vw;
            text-shadow: 0 1px 1px black;

            @media screen and (max-width: 1000px) {

                font-size: 35px;
    
            }

            @media screen and (max-width: 700px) {

                font-size: 30px;
    
            }

        }

    }

    > .btnWrapper {

        padding: 20px;

        a {
            color: inherit;
        }

        button {

            width: 200px;
            margin-right:7px;

            padding-top: 15px;
            padding-bottom: 15px;
            border-radius: 100px;

            @media screen and (max-width: 1000px) {

                width: 180px;
                
            }
            @media screen and (max-width: 750px) {

                padding-top:10px;
                padding-bottom: 10px;
                width: 150px;
                
            }

            @media screen and (max-width: 360px) {
                width: 120px;
                font-size: 10px;
            }

        }

    }

}