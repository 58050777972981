/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-11-22 12:17:36
 * @modify date 2018-11-22 12:17:36
 * @desc [description]
*/

@import '../../../main';

.stepsWrapper {

    @include flex;
    @include justify-content(center);
    @include align-items(flex-end);
    @include flex-wrap(wrap);

    @media screen and (max-width: 950px) {
        @include flex-direction(column);
        @include align-items(center);
    }

    > .text {
        margin-bottom: 40px;
        font-weight: bold;

        @media screen and (max-width: 950px) {
            margin:0;
        }

    }

    > .step {

        @include flex;
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);

        min-width: 400px;
        margin: 40px 20px;

        @media screen and (max-width: 1500px) {
            min-width: 320px;
        }

        @media screen and (max-width: 1250px) {
            min-width: 220px;
        }

        @media screen and (max-width: 950px) {
            min-width: 400px;
            margin: 20px;
        }

        @media screen and (max-width: 500px) {
            min-width: 220px;
        }

        &:first-child {
            .horizontal {
                position: relative;
                left: 40px;
            }
        }

        &:last-child {
            .horizontal {
                position: relative;
                right: 40px;
            }
        }

        > div {
            > .title {

                text-transform: uppercase;
                margin:20px 0;
                text-align: center;

            }
        }

        > .horizontal {

            display: block;

            @media screen and (max-width: 950px) {
                display: none;
            }

        }

        > .vertical {

            display: none;

            @media screen and (max-width: 950px) {
                display: block;
            }

        }

    }

    
    svg {

        @include drop-shadow(1px 1px 17px gray);

    }

}