/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2018-12-04 12:07:27
 * @modify date 2018-12-04 12:07:27
 * @desc [description]
*/

@import '../../main';

.modalWrapper {

    @include flex;
    @include justify-content(center);
    @include align-items(flex-start);
    @include translate(0,-120vh);
    @include transition(transform 0.25s ease-in-out);
    
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 1;

    &.visible {
        @include translate(0,0);
    }

    > .modal {
        background-color: white;
        padding: 15px;
        margin:100px 0;

        @media screen and (max-width: 850px) {
            width: 90%;
        }

        @media screen and (max-width: 450px) {
            width: 80%;
        }

        > .closeIcon {

            font-size: 20px;
            float: right;
            cursor: pointer;

        }

        > .titleWrapper {

            color: $theme-blue;

            > h3 {
                margin-top: 5px;
                color: #646161;
            }

        }

        input, textarea {

            @media screen and (max-width: 500px) {
                min-width: 200px;
            }
 
        }

    }

}